import React from 'react';
import FileSaver from 'file-saver';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {Translate} from '../translations';
// import hexometerScreenshot from '../../img/hexometer-screenshot.jpg';
import hexometerScreenshot from '../../img/Media-Screen.png';
import './styles.scss';

const MediaKitScreenshots = () => {
  const handleDownloadImg = () => {
    FileSaver.saveAs(hexometerScreenshot, 'Media-Screen.png');
  };

  return (
    <div className="row-screenshots">
      <div className="col-12 text-center">
        <h2 className="f_p f_size_30 l_height30 f_600 t_color3 mb_20">
          <Translate name="MEDIA_KIT_SCREENSHOT_TITLE" />
        </h2>
        <p>
          <Translate name="MEDIA_KIT_SCREENSHOT_TEXT" />
        </p>
        <img src={hexometerScreenshot} alt="Hexometer-screenshot" />
        <div
          className="mt-4 download"
          onClick={e => {
            e.preventDefault();
            handleDownloadImg();
          }}
        >
          <FontAwesomeIcon icon={faDownload} className="mr-2 f_size_12" />
          Download
        </div>
      </div>
    </div>
  );
};

export default MediaKitScreenshots;
