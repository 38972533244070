import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';
import MediaKitColors from '../../components/mediaKitColors';
import MediaKitAssets from '../../components/mediaKitAssets';
import MediaKitScreenshots from '../../components/mediaKitScreenshots';

const MediaKit = () => {
  const colors = [
    {
      RGB: '(22, 172, 89)',
      CMYK: '(59, 0, 33, 33)',
      HEX: '#16AC59',
    },
    {
      RGB: '(50, 53, 55)',
      CMYK: '(2, 1, 0, 78)',
      HEX: '#323537',
    },
  ];

  return (
    <Layout>
      <PageHero title="MEDIA_KIT_PAGE_TITLE" subtitle="MEDIA_KIT_PAGE_SUBTITLE" />
      <Section about>
        <Tabs />
        <MediaKitAssets />
        <MediaKitColors colors={colors} />
        <MediaKitScreenshots />
      </Section>
    </Layout>
  );
};

export default MediaKit;
