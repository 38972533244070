import React from 'react';
import { Translate } from '../translations';

const MediaKitAssets = () => (
  <div className="col-12">
    <h2 className="f_p f_size_30 l_height30 f_600 t_color3 mb_20 text-center">
      <Translate name="MEDIA_KIT_LOGO_TRADEMARK_TITLE" />
    </h2>
    <p>
      <Translate name="MEDIA_KIT_LOGO_TRADEMARK_TEXT" />
    </p>
  </div>
);

export default MediaKitAssets;
