import React, {FC, useState} from 'react';
import FileSaver from 'file-saver';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import {ColorBoxesProps} from '../../types';
import {Translate} from '../translations';
import Button from '../button';
// import assetsPDF from '../../pdf/Hexometer-logo-and-colors.pdf';
import assetsPDF from '../../pdf/Hexometer-Logo-and-Color-Instructions.pdf';
// import logo from '../../img/hexometer-logo-b2.svg';
import logo from '../../img/hexometer-logo.svg';
import './styles.scss';

const MediaKitColorBoxes: FC<ColorBoxesProps> = ({colors}) => {
  const [copiedColor, setCopiedColor] = useState('');

  const handleCopyToClipboard = (containerid: string) => {
    const hiddenField = document.getElementById(containerid + 'input') as HTMLInputElement;
    hiddenField.select();
    document.execCommand('copy');
    setCopiedColor(containerid);
    setTimeout(() => {
      setCopiedColor('');
    }, 1000);
  };

  const handleDownloadPdf = () => {
    FileSaver.saveAs(assetsPDF, 'Hexometer Logo and Color Instructions.pdf');
  };

  return (
    <div className="row-colors">
      <div className="col-12 col-lg-6 left-box">
        <div className="logo">
          <img src={logo} alt="Hexometer.com" className="mb-4" />
        </div>
        <Button primary onClick={handleDownloadPdf}>
          <Translate name="MEDIA_KIT_LOGO_TRADEMARK_DOWNLOAD_BTN_TEXT" />
        </Button>
      </div>
      <div className="col-12 col-lg-6 text-center right-box">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="f_p f_size_30 l_height30 f_600 t_color3 mb_20">
              <Translate name="MEDIA_KIT_COLORS_TITLE" />
            </h2>
            <p>
              <Translate name="MEDIA_KIT_COLORS_TEXT" />
            </p>
          </div>
          {colors.map(color => {
            const entries = Object.entries(color);
            return (
              <div key={color.HEX}>
                <div
                  id={color.HEX}
                  onClick={() => handleCopyToClipboard(color.HEX)}
                  className="col-12 col-sm-6 text-center color-box"
                  style={{background: `${color.HEX}`}}
                >
                  <div>
                    <input type="text" id={color.HEX + 'input'} defaultValue={color.HEX} />
                  </div>
                  <FontAwesomeIcon icon={faCopy} className="copy-icon" />
                </div>
                {entries.map(item => {
                  return (
                    <div key={item[1]} className="col-12">
                      {' '}
                      {item[0]}: {item[1]}{' '}
                    </div>
                  );
                })}
                {copiedColor === color.HEX && (
                  <div id={color.HEX} className="alert alert-success copied-message">
                    Color <strong>{color.HEX}</strong> is copied to clipboard
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MediaKitColorBoxes;
